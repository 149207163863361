/** @jsx jsx */
import { jsx } from "theme-ui"
import ArrowRight from "../../images/inline-svg/arrow-right.svg"

const buttonStyles = {
  bg: "#fff",
  boxShadow: "0 15px 20px 0 rgba(0, 0, 0, 0.16)",
  width: "6rem",
  height: "6rem",
  borderRadius: "100%",
  p: 0,
  border: 0,
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  '&:not(:disabled)': {
    cursor: "pointer",
  },
  "&:not(:disabled):hover": {
    bg: "blue",
    path: {
      fill: "#fff",
      opacity: 1,
    },
  },
  "&:not(:disabled):active": {
    bg: "blueAction",
  },
}

export const RightButton = props => (
  <button sx={buttonStyles} {...props}>
    <ArrowRight />
  </button>
)

export const LeftButton = props => (
  <button
    sx={{ ...buttonStyles, svg: { transform: "rotate(180deg)" } }}
    {...props}
  >
    <ArrowRight />
  </button>
)

import React, { useContext } from "react"

const CarouselContext = React.createContext({})

const CarouselState = ({ children, embla }) => (
  <CarouselContext.Provider value={{ embla }}>
    {children}
  </CarouselContext.Provider>
)

const useCarousel = () => {
  const context = useContext(CarouselContext);

  if (!context) {
    throw new Error(
      "useCarousel hook was used but a CarouselContext.Provider was not found in the parent tree. Make sure this is used in a component that is a child of Carousel"
    );
  }

  return context
}

export default CarouselState

export { CarouselContext, useCarousel }

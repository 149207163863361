/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { useCallback, useEffect, useState } from "react"
import { useEmblaCarousel } from "embla-carousel/react"
import { RightButton, LeftButton } from "../button/left-right"
import CarouselState from "../../contexts/carousel"
import useRecursiveTimeout from "../../utils/use-recursive-timeout"

const Carousel = ({
  children,
  childrenStyles,
  startIndex = 0,
  showArrows = false,
  showDots = false,
  loop = false,
  autoplay = false,
  delay = 4000,
}) => {
  const [viewportRef, embla] = useEmblaCarousel({
    loop,
    align: "start",
    startIndex,
  })
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState([])
  
  const autoplayCarousel = useCallback(() => {
    if (!embla) return
    if (embla.canScrollNext()) {
      embla.scrollNext()
    } else {
      embla.scrollTo(0)
    }
  }, [embla])
  
  const { play, stop } = useRecursiveTimeout(autoplayCarousel, delay)

  const scrollPrev = useCallback(() => {
    if (!embla) return
    embla.scrollPrev()
    stop()
  }, [embla, stop])
  
  const scrollNext = useCallback(() => {
    if (!embla) return
    embla.scrollNext()
    stop()
  }, [embla, stop])
  
  const scrollTo = useCallback(index => {
    if (!embla) return
    embla.scrollTo(index)
    stop()
  }, [embla, stop])

  const onSelect = useCallback(() => {
    if (!embla) return
    setSelectedIndex(embla.selectedScrollSnap())
    setPrevBtnEnabled(embla.canScrollPrev())
    setNextBtnEnabled(embla.canScrollNext())
  }, [embla, setSelectedIndex, setPrevBtnEnabled, setNextBtnEnabled])

  useEffect(() => {
    if (!embla) return
    onSelect()
    setScrollSnaps(embla.scrollSnapList())
    embla.on("select", onSelect)
    embla.on("pointerDown", stop)
  }, [embla, setScrollSnaps, onSelect, stop])
  
  useEffect(() => {
    if (autoplay) play()
  }, [play, autoplay])

  const buttonStyles = {
    position: "absolute",
    top: "calc(50% - 3rem)",
    left: 0,
    display: ["none", "unset"],
  }

  return (
    <div sx={{ gridColumn: "1 / -1 ", position: "relative" }}>
      <div ref={viewportRef} sx={{ overflow: "hidden" }}>
        <div
          sx={{
            display: "flex",
            "> *": {
              position: "relative",
              flexGrow: 0,
              flexShrink: 0,
              ...childrenStyles,
            },
          }}
        >
          <CarouselState embla={embla}>
            {typeof children === "function"
              ? children(embla)
              : children || null}
          </CarouselState>
        </div>
      </div>
      {showArrows && (
        <React.Fragment>
          <LeftButton
            onClick={scrollPrev}
            disabled={!prevBtnEnabled}
            sx={{
              ...buttonStyles,
              left: ["-5rem", null, "-7rem"],
            }}
          />
          <RightButton
            onClick={scrollNext}
            disabled={!nextBtnEnabled}
            sx={{
              ...buttonStyles,
              left: "auto",
              right: ["-5rem", null, "-7rem"],
            }}
          />
        </React.Fragment>
      )}
      {showDots && (
        <div
          sx={{
            display: ["none", "flex"],
            justifyContent: "center",
            mt: "4rem",
          }}
        >
          {scrollSnaps.map((_, index) => (
            <button
              type="button"
              key={index}
              sx={{
                border: 0,
                borderRadius: "100%",
                width: "1rem",
                height: "1rem",
                bg: selectedIndex === index ? "darkGray" : "lightGray",
                p: 0,
                cursor: "pointer",
                "&:hover": {
                  bg: "blue",
                },
                "&:active": {
                  bg: "blueAction",
                },
                "& + button": {
                  ml: "1.4rem",
                },
              }}
              onClick={() => scrollTo(index)}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default Carousel
